import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import 'dayjs/locale/en-ca'
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import { useField } from "formik";
import { makeStyles } from "@material-ui/core";
import dayjs from "dayjs";
import { useMemo } from 'react'

const useStyles = makeStyles({
  root: {
    width: '100%',
    ['& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl .MuiInputBase-input.MuiOutlinedInput-input']:
      {
        paddingTop: '12px',
        paddingBottom: '12px',
        fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
      },
  },
  wrapper: {
    marginBottom: '20px',
  },
  label: {
    fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
    letterSpacing: 'var(--letterSpacings-3)',
    fontSize: '14px',
    fontWeight: 500,
    display: 'block',
    marginBottom: '4px',
  },
})

export interface DatePickerInputProps {
  label?: string
  name: string
  value?: string
  required?: boolean
  icon?: string
}

const DatePickerInput = (props: DatePickerInputProps) => {
  const classes = useStyles()
  const [field, meta, helpers] = useField(props)

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className={classes.wrapper}>
        <label className={classes.label}>{props.label}</label>
        <DatePicker
          className={classes.root}
          value={field?.value}
          onChange={(newVal) => {
            if (newVal && dayjs(newVal).isValid()) {
              helpers.setValue(dayjs(newVal).format('YYYY-MM-DD').toString())
              helpers.setError(undefined)
            } else {
              helpers.setError('Invalid date')
            }
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                error={!!meta.error}
                helperText={!!meta.error ? meta.error : null}
              />
            )
          }}
        />
      </div>
    </LocalizationProvider>
  )
}

export default DatePickerInput

