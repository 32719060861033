import MultiselectField from "@/components/common/fields/MultiselectField"
import SelectField from "@/components/common/fields/SelectField"
import TextareaField from "@/components/common/fields/TextareaField"
import InputField from "@/components/common/fields/InputField"
import { CustomField } from "@/api/interface/custom-field"
import CheckboxInput from "@/components/common/forms/formik/CheckboxInput"
import { FormControl, FormGroup, FormLabel, RadioGroup } from "@mui/material"
import RadioInput from "@/components/common/forms/formik/RadioInput"
import DatePickerInput from "@/components/common/forms/formik/DatePickerInput"
import SimpleTimePickerFieldGroup from "@/components/common/forms/formik/SimpleTimePickerFieldGroup"

interface Props {
  customFields: Array<CustomField>
  handleQueueIdChange?: Function
  values?: any
  setFieldValue?: Function
  setErrors?: Function
  setTouched?: Function
  currentQueueSelectOption?: string
  location?: string
  publicQueueSelectHandler?: Function
  customQueueSelectValue?: string
}

export default function CreateEditPartyCustomFields({
  customFields,
  customQueueSelectValue,
}: Props) {
  const mapOptions = (options: CustomField["options"], type: string) => {
    return options?.map((x) => ({
      ...x,
      label: x.value,
      value: x.value,
      data: { ...x.data, icon: x.icon, valueMeta: x.valueMeta },
    }))
  }

  return (
    <>
      {customFields.map((cf, i) => {
        const fieldProps = {
          key: i,
          name: `customFields[${i}].value`,
          label: cf.name,
          required: cf.isRequired,
          icon: cf.icon,
        }
        const selectOptions = {
          options: mapOptions(cf.options, cf.type),
        }

        switch (cf.type) {
          case "checkbox":
          // return (
          //   <FormControl>
          //     <FormLabel>{fieldProps.label}</FormLabel>
          //     <FormGroup>
          //       {selectOptions.options.map((x, i) => (
          //         <CheckboxInput label={x.label} />
          //       ))}
          //     </FormGroup>
          //   </FormControl>
          // )
          case "multiselect":
            return <MultiselectField {...fieldProps} {...selectOptions} />
          case "radio":
          // return (
          //   <FormControl>
          //     <FormLabel>{fieldProps.label}</FormLabel>
          //     <RadioGroup
          //       name={fieldProps.name}
          //       //   defaultValue={selectOptions.options[0]?.value}
          //     >
          //       {selectOptions.options.map((x, i) => (
          //         <RadioInput key={i} label={x.label} value={x.value} />
          //       ))}
          //     </RadioGroup>
          //   </FormControl>
          // )
          case "select":
            return (
              <SelectField
                fullWidth
                showEmptyOption
                {...fieldProps}
                {...selectOptions}
              />
            )
          case "text":
            return <TextareaField fullWidth={true} {...fieldProps} />
          case "number":
            return (
              <InputField
                type={cf.type}
                // min={cf.settings?.min || 0}
                // max={cf.settings?.max || 5000}
                // step={cf.settings?.step || 1}
                fullWidth={true}
                {...fieldProps}
              />
            )
          case "date":
            return (
              <DatePickerInput
                label={"datepicker"}
                value={cf.value}
                {...fieldProps}
              />
            )
          case "email":
          case "tel":
          case "string":
            const inputType = cf.type === "string" ? "text" : cf.type
            return (
              <InputField type={inputType} fullWidth={true} {...fieldProps} />
            )
          case "time":
            return <SimpleTimePickerFieldGroup {...fieldProps} />
          case "queueselect":
            return (
              <input
                type="hidden"
                value={customQueueSelectValue || ""}
                {...fieldProps}
              ></input>
            )
          default:
            console.error("Cannot render customfield with unknown type", cf)
        }
      })}
    </>
  )
}
