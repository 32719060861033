interface Province {
  name: string
  abbreviation: string
}

interface Country {
  name: string
  abbreviation: string
  states: Province[]
}

// https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes (Alpha 2)
export const countries: Country[] = [
  {
    abbreviation: "US",
    name: "United States of America",
    states: [
      {
        abbreviation: "AL",
        name: "Alabama",
      },
      {
        abbreviation: "AK",
        name: "Alaska",
      },
      {
        abbreviation: "AS",
        name: "American Samoa",
      },
      {
        abbreviation: "AZ",
        name: "Arizona",
      },
      {
        abbreviation: "AR",
        name: "Arkansas",
      },
      {
        abbreviation: "CA",
        name: "California",
      },
      {
        abbreviation: "CO",
        name: "Colorado",
      },
      {
        abbreviation: "CT",
        name: "Connecticut",
      },
      {
        abbreviation: "DE",
        name: "Delaware",
      },
      {
        abbreviation: "DC",
        name: "District Of Columbia",
      },
      {
        abbreviation: "FM",
        name: "Federated States Of Micronesia",
      },
      {
        abbreviation: "FL",
        name: "Florida",
      },
      {
        abbreviation: "GA",
        name: "Georgia",
      },
      {
        abbreviation: "GU",
        name: "Guam",
      },
      {
        abbreviation: "HI",
        name: "Hawaii",
      },
      {
        abbreviation: "ID",
        name: "Idaho",
      },
      {
        abbreviation: "IL",
        name: "Illinois",
      },
      {
        abbreviation: "IN",
        name: "Indiana",
      },
      {
        abbreviation: "IA",
        name: "Iowa",
      },
      {
        abbreviation: "KS",
        name: "Kansas",
      },
      {
        abbreviation: "KY",
        name: "Kentucky",
      },
      {
        abbreviation: "LA",
        name: "Louisiana",
      },
      {
        abbreviation: "ME",
        name: "Maine",
      },
      {
        abbreviation: "MH",
        name: "Marshall Islands",
      },
      {
        abbreviation: "MD",
        name: "Maryland",
      },
      {
        abbreviation: "MA",
        name: "Massachusetts",
      },
      {
        abbreviation: "MI",
        name: "Michigan",
      },
      {
        abbreviation: "MN",
        name: "Minnesota",
      },
      {
        abbreviation: "MS",
        name: "Mississippi",
      },
      {
        abbreviation: "MO",
        name: "Missouri",
      },
      {
        abbreviation: "MT",
        name: "Montana",
      },
      {
        abbreviation: "NE",
        name: "Nebraska",
      },
      {
        abbreviation: "NV",
        name: "Nevada",
      },
      {
        abbreviation: "NH",
        name: "New Hampshire",
      },
      {
        abbreviation: "NJ",
        name: "New Jersey",
      },
      {
        abbreviation: "NM",
        name: "New Mexico",
      },
      {
        abbreviation: "NY",
        name: "New York",
      },
      {
        abbreviation: "NC",
        name: "North Carolina",
      },
      {
        abbreviation: "ND",
        name: "North Dakota",
      },
      {
        abbreviation: "MP",
        name: "Northern Mariana Islands",
      },
      {
        abbreviation: "OH",
        name: "Ohio",
      },
      {
        abbreviation: "OK",
        name: "Oklahoma",
      },
      {
        abbreviation: "OR",
        name: "Oregon",
      },
      {
        abbreviation: "PW",
        name: "Palau",
      },
      {
        abbreviation: "PA",
        name: "Pennsylvania",
      },
      {
        abbreviation: "PR",
        name: "Puerto Rico",
      },
      {
        abbreviation: "RI",
        name: "Rhode Island",
      },
      {
        abbreviation: "SC",
        name: "South Carolina",
      },
      {
        abbreviation: "SD",
        name: "South Dakota",
      },
      {
        abbreviation: "TN",
        name: "Tennessee",
      },
      {
        abbreviation: "TX",
        name: "Texas",
      },
      {
        abbreviation: "UT",
        name: "Utah",
      },
      {
        abbreviation: "VT",
        name: "Vermont",
      },
      {
        abbreviation: "VI",
        name: "Virgin Islands",
      },
      {
        abbreviation: "VA",
        name: "Virginia",
      },
      {
        abbreviation: "WA",
        name: "Washington",
      },
      {
        abbreviation: "WV",
        name: "West Virginia",
      },
      {
        abbreviation: "WI",
        name: "Wisconsin",
      },
      {
        abbreviation: "WY",
        name: "Wyoming",
      },
    ],
  },
  {
    abbreviation: "CA",
    name: "Canada",
    states: [
      {
        abbreviation: "NL",
        name: "Newfoundland and Labrador",
      },
      {
        abbreviation: "PE",
        name: "Prince Edward Island",
      },
      {
        abbreviation: "NS",
        name: "Nova Scotia",
      },
      {
        abbreviation: "NB",
        name: "New Brunswick",
      },
      {
        abbreviation: "QC",
        name: "Quebec",
      },
      {
        abbreviation: "ON",
        name: "Ontario",
      },
      {
        abbreviation: "MB",
        name: "Manitoba",
      },
      {
        abbreviation: "SK",
        name: "Saskatchewan",
      },
      {
        abbreviation: "AB",
        name: "Alberta",
      },
      {
        abbreviation: "BC",
        name: "British Columbia",
      },
      {
        abbreviation: "YT",
        name: "Yukon",
      },
      {
        abbreviation: "NT",
        name: "Northwest Territories",
      },
      {
        abbreviation: "NU",
        name: "Nunavut",
      },
    ],
  },
  {
    name: "United Kingdom",
    abbreviation: "GB",
    states: [
      {
        abbreviation: "",
        name: "Default"
      },
    ],
  }
  //   { name: "Mexico", abbreviation: "MX" },
]

export const pickStatesForCountry = (country?: string) =>
  countries.find((x) => x.abbreviation === country)?.states ||
  countries[0].states

export const PHONE_COUNTRY_INCLUSIONS = [
  'us',
  'ca',
  'gb',
  'ua',
]

export const COUNTRIES_NO_STATE = ['GB']
