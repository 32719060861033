import React, { useState } from "react"
import PropTypes from "prop-types"
import Label from "@/components/common/forms/Label"
import { HelperText } from "@/components/common/forms/Common"
import { styled } from "@/styles/theme"
import { toSnakeCase } from "case-converter"

const StyledTextarea = styled("textarea", {
  color: "$slate",
  fontSize: "$body",
  fontFamily: "inherit",
  padding: "$4",
  marginBottom: "$2",
  lineHeight: "$3",
  minHeight: "110px",
  display: "block",
  backgroundColor: "$white",
  border: "$sizes$px solid $colors$border",
  borderRadius: "$1",
  position: "relative",
  "&:active, &:focus": {
    border: "2px solid $colors$blue",
    outline: "none",
  },
  "&:disabled, &.disabled": {
    backgroundColor: "$gray-light",
    cursor: "not-allowed",
  },
  variants: {
    fullWidth: {
      true: {
        width: "100%",
      },
    },
  },
})

const TextareaWrapper = styled("div", {
  marginBottom: "$1",
})

export const Textarea = React.forwardRef((props, ref) => {
  const {
    enforceMaxLength,
    id,
    info,
    label,
    maxLength,
    onChange,
    showMaxLength,
    value,
    ...rest
  } = props
  const [charsRemaining, setCharsRemaining] = useState(
    maxLength - (value?.length || 0)
  )
  const fieldId = id || toSnakeCase(label)

  return (
    <TextareaWrapper>
      {label && (
        <Label htmlFor={fieldId}>
          {label}
          {props?.required ? "*" : ""}
        </Label>
      )}
      {info && <HelperText>{info}</HelperText>}
      <StyledTextarea
        ref={ref}
        id={fieldId}
        onChange={(e) => {
          const charCount = e.target?.value?.length
          if (enforceMaxLength && charCount > maxLength) {
            e.preventDefault()
          } else {
            setCharsRemaining(maxLength - charCount)
            onChange?.(e)
          }
        }}
        value={value}
        {...rest}
      />
      {showMaxLength && (
        <HelperText hasError={enforceMaxLength && charsRemaining <= 0}>
          {maxLength - charsRemaining} / {maxLength} characters
        </HelperText>
      )}
    </TextareaWrapper>
  )
})

Textarea.displayName = "Textarea"

Textarea.defaultProps = {
  label: "",
  maxLength: 250,
  showMaxLength: true,
  fullWidth: false,
  disabled: false,
}

Textarea.propTypes = {
  label: PropTypes.string,
  maxLength: PropTypes.number,
  showMaxLength: PropTypes.bool,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
}
