import { useField } from "formik"
import Select from "@/components/common/forms/Select"
import { HelperText, FieldWrapper } from "@/components/common/forms/Common"

function SelectField(props) {
  const [field, meta] = useField(props)

  return (
    <FieldWrapper>
      <Select {...field} {...props} />
      {props.notes && (
        <HelperText hasError={false}><span dangerouslySetInnerHTML={{ __html: props.notes }}></span></HelperText>
      )}
      <HelperText hasError={true}>
        {meta.touched && meta.error ? meta.error : null}
      </HelperText>
    </FieldWrapper>
  )
}

export default SelectField
