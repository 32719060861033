import { FormikHelpers } from 'formik'
import { Location } from '@/api/interface/location'
import {
  PhoneInput,
  defaultCountries,
  parseCountry,
} from 'react-international-phone'
import 'react-international-phone/style.css'
import { Box, Grid } from '@material-ui/core'
import StyledLabel from '../Label'
import intlPhoneStyles from '@/styles/components/InternationalPhoneInput.module.scss'
import { HelperText } from '../Common'
import { useField } from 'formik'
import { useMemo, useState } from 'react'
import classNames from 'classnames'
import { intlPhoneTest } from '@/utils/formik-validators'
import { PHONE_COUNTRY_INCLUSIONS } from '@/consts/countries'

interface Props extends Pick<FormikHelpers<Location>, 'setFieldValue'> {
  name: string
  required?: boolean
  disabled?: boolean
  label?: string
  defaultCountry: string
  handleChange?: Function
  submitCount?: number
  applyBizCreateStyles?: any
  disableDialCodeAndPrefix?: boolean
  showDisabledDialCodeAndPrefix?: boolean
}

// use to get filtered country list if needed
const countries = defaultCountries.filter((country) => {
  const { iso2 } = parseCountry(country)
  return PHONE_COUNTRY_INCLUSIONS.includes(iso2)
})

export default function InternationalPhoneInput({
  disabled,
  name,
  label,
  required,
  defaultCountry = 'us',
  handleChange,
  submitCount,
  applyBizCreateStyles,
  disableDialCodeAndPrefix = false,
  showDisabledDialCodeAndPrefix = false
}: Props) {
  const [field, meta, helpers] = useField(name)
  const [error, setError] = useState('')
  const [isFieldTouched, setIsFieldTouched] = useState(false)
  const [isInputFocused, setIsInputFocused] = useState(false)

  const fieldValue = useMemo(() => {
    return field?.value || ''
  }, [field])

  // just for different styling on Biz Create form
  const bizCreateBoxClasses = applyBizCreateStyles
    ? intlPhoneStyles['bizCreateIntlPhoneBoxWrapper']
    : null
  const bizCreateInputClasses = applyBizCreateStyles
    ? intlPhoneStyles['bizCreateIntlPhoneInput']
    : null
  const bizCreateLabelClasses = applyBizCreateStyles
    ? intlPhoneStyles['bizCreateIntlPhoneLabel']
    : null
  const bizCreateBtnClasses = applyBizCreateStyles
    ? intlPhoneStyles['bizCreateIntlPhoneBtn']
    : null

  const errorText = useMemo(() => {
    if (required) {
      return meta?.error
    }
    if (isFieldTouched || submitCount > 0) {
      return meta?.error
    }
    return ''
  }, [required, field, meta, isFieldTouched, submitCount])

  const isError = useMemo(() => {
    return (
      (isFieldTouched || submitCount > 0) && meta?.error && meta?.error !== ''
    )
  }, [error, field, meta, isFieldTouched, submitCount])

  const containerStateClasses = useMemo(() => {
    if (isInputFocused && !isError) {
      return intlPhoneStyles['active']
    }
    if (isError) {
      return intlPhoneStyles['error']
    }
    return ''
  }, [isError, isInputFocused])

  const btnStateClasses = useMemo(() => {
    if (isInputFocused && !isError) {
      return intlPhoneStyles['btnActive']
    }
    if (isError) {
      return intlPhoneStyles['btnError']
    }
    return ''
  }, [isError, isInputFocused])

  const validatePhoneInput = async (value) => {
    return await intlPhoneTest(value)
  }

  return (
    <Box className={bizCreateBoxClasses}>
      <Grid container>
        <Grid item xs={12}>
          <StyledLabel className={bizCreateLabelClasses}>
            {`${label || 'Phone'}${required ? ' *' : ''}`}
          </StyledLabel>
        </Grid>
        <Grid item xs={12}>
          <PhoneInput
            disabled={disabled}
            defaultCountry={defaultCountry === 'ca' ? 'ca' : defaultCountry}
            name={name}
            value={fieldValue}
            required={required}
            disableDialCodeAndPrefix={disableDialCodeAndPrefix}
            showDisabledDialCodeAndPrefix={disableDialCodeAndPrefix}
            className={classNames(
              intlPhoneStyles.intlPhoneContainer,
              containerStateClasses
            )}
            inputClassName={classNames(
              intlPhoneStyles.intlPhoneInput,
              bizCreateInputClasses
            )}
            inputProps={{
              //@ts-ignore
              'data-testid': 'phoneInput',
              autoComplete: "tel",
              onBlur: (e) => {
                setIsFieldTouched(true)
                setIsInputFocused(false)
              },
              onFocus: (e) => {
                setIsInputFocused(true)
              },
            }}
            onChange={async (phone) => {
              handleChange(phone)
              const isValid = phone?.length
                ? await validatePhoneInput(phone)
                : true

              if (!isValid) {
                helpers.setError('Invalid phone number')
                setError('Invalid phone number')
              } else {
                helpers.setError('')
                setError('')
              }
            }}
            dialCodePreviewStyleProps={{
              style: {
                wordBreak: 'normal',
                fontSize: '16px',
                color: '#757575',
                border: 'none',
              },
            }}
            countrySelectorStyleProps={{
              buttonStyle: {
                padding: '16px 8px 16px 16px',
                height: '40px',
                borderRightWidth: 0,
                border: '0 solid rgba(0, 0, 0, 0)',
                borderWidth: 0,
                borderColor: 'unset',
              },
              buttonClassName: classNames(
                intlPhoneStyles.btnCountrySelector,
                btnStateClasses,
                bizCreateBtnClasses
              ),
            }}
          />
          {!applyBizCreateStyles && (
            <HelperText hasError={true}>{isError && errorText}</HelperText>
          )}
          {applyBizCreateStyles && isFieldTouched && meta?.error && (
            <HelperText hasError={true}>{meta?.error && meta.error}</HelperText>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}
