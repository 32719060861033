import { Grid, Box } from "@mui/material"
import SelectField from "../../fields/SelectField"
import { useEffect, useState } from "react"
import dayjs from "dayjs"
import { useField } from "formik"
import { useFormikContext } from "formik"
import { useMemo } from "react"

const addLeadingZero = (value: string) => {
  if (parseInt(value) < 10) {
    return String(value).padStart(2, "0")
  }
  return value
}

const stripLeadingZero = (value: string) => {
  const arr = value.split("")
  if (arr.length > 1 && arr[0] === "0") {
    return arr[1]
  }
  return value
}

const generateOptions = (optionType: string) => {
  const numArr = [...Array(13).keys()]
  if (optionType === "hour") {
    return numArr.map((item, i) => {
      if (i === 0) {
        return {
          label: "",
          value: "",
        }
      }
      return {
        label: item,
        value: item,
      }
    })
  }
  if (optionType === "minutes") {
    return numArr.map((item, i) => {
      if (i === 0) {
        return {
          label: "",
          value: "",
        }
      }
      return {
        label: addLeadingZero(String((item - 1) * 5)),
        value: String((item - 1) * 5),
      }
    })
  }
}

const convertHourTo24 = (hour, meridiem) => {
  if (hour === "") {
    return ""
  }
  if (meridiem === "pm") {
    return (parseInt(hour) + 12).toString()
  }
  return hour
}

const convertHourto12 = (hour) => {
  const hourNum = parseInt(hour)

  if (hourNum > 12) {
    return {
      hour: (hourNum - 12).toString(),
      meridiem: "pm",
    }
  }
  return {
    hour: hour,
    meridiem: "am",
  }
}

const splitHourMinutesMeridiem = (value) => {
  if (!value || value === "") {
    return {
      hour: "",
      minutes: "",
      meridiem: "am",
    }
  }
  const split = value.split(":")
  const hour12 = convertHourto12(split[0])
  return {
    hour: stripLeadingZero(hour12.hour),
    minutes: stripLeadingZero(split[1]),
    meridiem: hour12.meridiem,
  }
}

const SimpleTimePickerFieldGroup = (props) => {
  const { name, label } = props
  const [field, meta, helpers] = useField(props)

  const { setFieldValue, values, setFieldError } = useFormikContext()
  const [hourValue, setHourValue] = useState(
    splitHourMinutesMeridiem(field.value).hour
  )
  const [minutesValue, setMinutesValue] = useState(
    splitHourMinutesMeridiem(field.value).minutes
  )
  const [meridiemValue, setMeridiemValue] = useState(
    splitHourMinutesMeridiem(field.value).meridiem
  )

  useEffect(() => {
    if (field.value === "") {
      setHourValue("")
      setMinutesValue("")
      setMeridiemValue("am")
    }
  }, [field])

  const fieldIndex = useMemo(() => name.match(/^.*([0-9]).*$/)[1], [name])

  const handleChange = (e, fieldType) => {
    const hourFieldName = `customFieldHour-${fieldIndex}`
    const minutesFieldName = `customFieldMinutes-${fieldIndex}`
    const meridiemFieldName = `customFieldMeridiem-${fieldIndex}`

    const meridiem = fieldType === "meridiem" ? e.target.value : meridiemValue
    const hour = fieldType === "hour" ? e.target.value : hourValue
    let minutes = fieldType === "minutes" ? e.target.value : minutesValue
    const convertedHour = convertHourTo24(hour, meridiem)
    const hourMinutes = ""
    let seconds = ""
    let hoursMinutesSep = ""

    setHourValue(hour)
    setMinutesValue(minutes)
    setMeridiemValue(meridiem)

    if (convertedHour === "" && minutes !== "") {
      setFieldError(hourFieldName, "Hour field cannot be empty")
    }

    if (convertedHour !== "" && minutes === "") {
      minutes = "0"
    }
    if (convertedHour !== "0" && minutes !== "") {
      seconds = ":00"
      hoursMinutesSep = ":"
    }

    const timeString = `${addLeadingZero(
      convertedHour
    )}${hoursMinutesSep}${addLeadingZero(minutes)}${seconds}`
    // populate hidden field
    helpers.setValue(timeString)
  }

  return (
    <Box>
      <Grid container wrap="nowrap">
        <Grid item xs={12}>
          <label
            style={{
              fontFamily:
                "Montserrat, Helvetica, Arial, apple-system, sans-serif",
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "21px",
              color: "rgba(0, 0, 0, 0.87)",
              marginBottom: "4px",
            }}
          >
            {label}
          </label>
        </Grid>
      </Grid>
      <Grid container spacing={1} wrap="nowrap">
        <Grid item xs={4} sm={4} lg={4} xl={4}>
          <SelectField
            key={`customFieldHour-${fieldIndex}`}
            // label="Hour"
            value={hourValue}
            options={generateOptions("hour")}
            name={`customFieldHour-${fieldIndex}`}
            onChange={(e) => {
              return handleChange(e, "hour")
            }}
            style={{
              width: "100%",
            }}
          />
        </Grid>
        {/* <Grid item xs={2} sm={2} lg={2} xl={2}>
          :
        </Grid> */}
        <Grid item xs={4} sm={4} lg={4} xl={4}>
          <SelectField
            key={`customFieldMinutes-${fieldIndex}`}
            // label="Minutes"
            value={minutesValue}
            options={generateOptions("minutes")}
            name={`customFieldMinutes-${fieldIndex}`}
            onChange={(e) => {
              return handleChange(e, "minutes")
            }}
            style={{
              width: "100%",
            }}
          />
        </Grid>
        <Grid item xs={4} sm={4} lg={4} xl={4}>
          <SelectField
            key={`customFieldMeridiem-${fieldIndex}`}
            // label={" "}
            value={meridiemValue}
            name={`customFieldMeridiem-${fieldIndex}`}
            options={[
              { label: "AM", value: "am" },
              { label: "PM", value: "pm" },
            ]}
            onChange={(e) => {
              return handleChange(e, "meridiem")
            }}
            style={{
              width: "100%",
            }}
          />
        </Grid>
        <input type="hidden" name={name} key={name} />
      </Grid>
    </Box>
  )
}

export default SimpleTimePickerFieldGroup
