export default {
  text: "Freeform Text",
  string: "Short Text",
  multiselect: "Multiselect Dropdown",
  select: "Dropdown",
  //   checkbox: "Checkbox",
  //   radio: "Radio Input",
  tel: "Telephone",
  email: "Email Address",
  number: "Number Input",
  date: "Date",
  time: "Time Input",
  queueselect: "Queue Assignment",
}

// CF types eligible to be made dynamic
export const DYNAMIC_CF_TYPES = [
  'number',
  'string',
]
