// @TODO - convert appropriate consts to ts enums and other ts datastructures
export const planTypes = {
    FREE: 'free',
    TRIAL: 'trial', 
    STARTER: 'starter',
    BUSINESS: 'business',
    CUSTOM: 'custom',
}

export const calloutCardMessages = {
    APPROACHING_LIMIT: {
        color: '#FFC165',
        title: 'Approaching Message Limit',
        message: 'Any messages sent over your limit will incur a standard $.02 per message cost reflected in a future invoice.',
    },
    LIMIT_REACHED: {
        color: '#E85757',
        title: 'Message Limit Reached',
        message: 'Any messages sent over your limit will incur a standard $.02 per message cost reflected in a future invoice.',
    },
    CAP_REACHED: {
        color: '#E85757',
        title: 'Message Cap Reached',
        message: 'You will no longer be able to add guests to the waitlist. Please upgrade your plan or wait until the start of your next billing period.',
    },
    TRIAL_EXPIRED: {
        color: '#E85757',
        title: 'Your Trial Period Expired',
        message: 'The premium settings and data you created during your trial will be stored for 14 days after expiration. Subscribe to a paid plan within that period to restore your data.',
    },
    CANCELLATION_SCHEDULED: {
        color: '#E85757',
        title: 'Your Plan is Scheduled for Cancellation',
        message: 'Your plan will be canceled on <<date>>. On that date, you will be downgraded to the Free Plan and will no longer have access to premium features. Please review our <a href="https://intercom.help/nextme-app/en/articles/8032162-data-retention-policy">Data Retention Policy</a> to learn how long we store your premium data. We\’re sad to see you go, but thanks for using NextMe!',
    },
    CANCELED: {
        color: '#E85757',
        title: 'Don\’t Lose Your Premium Data',
        message: 'The premium settings and data you created will be stored for 14 days. Subscribe to a paid plan within that period to restore your data.',
    },
    PAST_DUE: {
        color: '#E85757',
        title: 'Your Bill is Past Due',
        message: 'Please pay now to avoid any service interruption and to avoid losing any of your premium data.',
    },
}

export const subscriptionDays = {
    DATA_GRACE_PERIOD: -14,
    TRIAL_LENGTH_DAYS: 14,
}

export const subscriptionStatuses = {
    ALL: ['active', 'past_due', 'unpaid', 'canceled', 'incomplete', 'incomplete_expired', 'trialing', 'paused', 'all', 'ended'],
    ACTIVE: ['active', 'past_due'],
    ENTITLED: ['active', 'trialing'],
    INACTIVE: ['canceled', 'paused', 'ended'],
}

export const featureNames = {
    VWR: 'vwr',
    SCI: 'sci',
    LOCATIONS: 'locations',
    TEAM: 'unlimited_users',
    CUSTOM_BRANDING: 'customBranding',
    ANALYTICS: 'automated_reports',
    NOTIFICATION_SETTINGS: 'notification_settings',
    MARKETING_MESSAGES: 'marketing_messages',
    PUBLIC_WAITLISTS: 'publicWaitlists',
    BOOKINGS: 'bookings',
}

export const FEATURE_DIALOG_CONTENT = {
    vwr: {
        heroImg: '/img/subscription/vwr-upgrade.png',
        heroAlt: '',
        heroMaxWidth: 600,
        heroMaxHeight: 400,
        title: 'Unlock Your Virtual Waiting Room',
        body: 'The Virtual Waiting Room is a powerful way to engage with your guests while they wait and showcase your brand. Upgrade your plan today to access this feature.',
        ctas: [],
    },
    sci: {
        heroImg: '/img/subscription/sci-upgrade.png',
        heroAlt: '',
        heroMaxWidth: 600,
        heroMaxHeight: 400,
        title: 'Unlock Self Check-in',
        body: 'Self Check-in gives you the ability to let guests join the wait themselves from your website, QR code, or kiosk. Upgrade your plan today to access this feature.',
        ctas: [],
    },
    locations: {
        heroImg: '/img/browser-location-access.png',
        heroAlt: '',
        heroMaxWidth: 1200,
        heroMaxHeight: 800,
        title: 'Unlock Additional Locations',
        body: 'You\’ve reached the maximum number of locations included with your current plan quantity. To add additional locations, simply click "Upgrade" and then "Manage Plan" to increase your subscription quantity for an additional fee. If you\'re on a Custom plan, please start a chat with our support team to request additional locations.',
        ctas: [],
    },
    unlimited_users: {
        heroImg: '/img/browser-location-access.png',
        heroAlt: '',
        heroMaxWidth: 1200,
        heroMaxHeight: 800,
        title: 'Unlock Additional Team Members',
        body: 'You\’ve reached the maximum number of team members included in your current plan level. Consider upgrading your plan to add support for additional users.',
        ctas: [],
    },
    automated_reports: {
        heroImg: '/img/subscription/analytics-upgrade.png',
        heroAlt: '',
        heroMaxWidth: 1200,
        heroMaxHeight: 800,
        title: 'Unlock Analytics',
        body: 'Access the insights you need to help your business grow. Download waitlist and guest reports to better understand your performance.',
        ctas: [],
    },
    notification_settings: {
        heroImg: '/img/subscription/analytics-upgrade.png',
        heroAlt: '',
        heroMaxWidth: 1200,
        heroMaxHeight: 800,
        title: 'Unlock Customizable Notifications',
        body: 'Upgrade to a paid plan to edit and personalize the messages you send to guests.',
        ctas: [],
    },
    message_cap: {
        heroImg: '/img/subscription/analytics-upgrade.png',
        heroAlt: '',
        heroMaxWidth: 1200,
        heroMaxHeight: 800,
        title: 'Message Cap Reached',
        body: 'You\'ve reached your message cap for this billing cycle and can no longer add guests to the waitlist. Please upgrade your plan or wait until the start of your next billing period.',
        ctas: [],
    },
    marketing_messages: {
        heroImg: '/img/subscription/analytics-upgrade.png',
        heroAlt: '',
        heroMaxWidth: 1200,
        heroMaxHeight: 800,
        title: 'Unlock Marketing Messages',
        body: 'Marketing messages allow you to follow up with your guests after they leave your business. Gather reviews, send surveys, or launch reengagement campaigns. This feature requires an upgrade to the Business or Custom plan.',
        ctas: [],
    },
    publicWaitlists: {
        heroImg: '/img/subscription/public-waitlist-upgrade.png',
        heroAlt: '',
        heroMaxWidth: 1200,
        heroMaxHeight: 800,
        title: 'Unlock Your Public Waitlist',
        body: 'Generate a guest-facing version of your waitlist so guests can easily check their status relative to others.',
        ctas: [],
    },
    bookings: {
        heroImg: '/img/subscription/bookings-upgrade.png',
        heroAlt: '',
        heroMaxWidth: 1200,
        heroMaxHeight: 800,
        title: 'Unlock Bookings',
        body: 'Schedule guests in advance and seamlessly integrate them into your waitlist. This feature requires an upgrade to the Business or Custom plan.',
        ctas: [],
    }
}

export const SUPPORT_LINKS = {
    trial: 'https://intercom.help/nextme-app/en/articles/7850369-your-14-day-free-trial',
    free: 'https://intercom.help/nextme-app/en/articles/7852620-free-plan',
    starter: 'https://intercom.help/nextme-app/en/articles/7852568-starter-plan',
    business: 'https://intercom.help/nextme-app/en/articles/7852595-business-plan',
    custom: 'https://intercom.help/nextme-app/en/articles/7852608-custom-plan',
}

export const PLAN_BADGES = {
    free: {
        text: 'Free Plan',
        color: 'white',
    },
    trial: {
        text: 'Free Trial',
        color: 'blue',
    },
    starter: {
        text: 'Starter Plan',
        color: 'green',
    },
    business: {
        text: 'Business Plan',
        color: 'blue',
    },
    custom: {
        text: 'Custom Plan',
        color: 'yellow',
    },
}